<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th
            v-for="(item, idx) in headers"
            :key="idx"
            :class="`text-${item.align}`"
          >
            {{ item.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, idx) in items"
          :key="idx"
          @dblclick="
            $router.push({ name: 'orders-edit', params: { id: item.id } })
          "
        >
          <td>{{ item.id }}</td>
          <td class="text-center">
            <StatusChip
              :status="orderStatus"
              :current-status="item.order_status"
            />
          </td>
          <td>
            <ContactInfo
              :name="JSON.parse(item.budget.client.client_contact)[0].name"
              :phone-one="
                JSON.parse(item.budget.client.client_contact)[0].phone_number
              "
              :phone-two="
                JSON.parse(item.budget.client.client_contact)[0].cel_number
              "
              :email="JSON.parse(item.budget.client.client_contact)[0].email"
            />
          </td>
          <td>{{ item.company_name }}</td>
          <td class="text-right">
            {{ item.budget.subtotal | formatMoney }}
          </td>
          <td class="text-right">
            {{ item.approved_registration_date | formatDate }}
          </td>
          <td class="text-right">
            {{ item.approved_order_date | formatDate }}
          </td>
          <td class="text-right">
            {{ item.delivery_date | formatDate }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import StatusChip from '@/components/data-table/StatusChip'
import ContactInfo from '@/components/data-table/ContactInfo'

export default {
  components: {
    StatusChip,
    ContactInfo,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    items: [],
    orderStatus: [
      { value: 1, text: 'Pedido Emitido', color: 'blue-grey' },
      { value: 2, text: 'Aprovação de cadastro', color: 'indigo' },
      { value: 3, text: 'Aprovação de pedido', color: 'cyan' },
      { value: 4, text: 'Compras', color: 'deep-purple' },
      { value: 5, text: 'Pedido finalizado', color: 'success' },
      { value: 6, text: 'Arquivado', color: 'greyy' },
      { value: 7, text: 'Cancelado', color: 'error' },
    ],
    headers: [
      { text: 'Pedido' },
      { text: 'Status', align: 'center' },
      { text: 'Contato' },
      { text: 'Empresa' },
      { text: 'Total (R$)', align: 'end' },
      { text: 'Data de Registro', align: 'end' },
      { text: 'Data de Aprovação', align: 'end' },
      { text: 'Data de Entrega', align: 'end' },
    ],
  }),
  created() {
    this.getOrders()
  },
  methods: {
    getOrders() {
      this.$api
        .post('order/client', { client_id: this.client.id })
        .then((res) => {
          this.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<!-- {
	"status": "success",
	"data": [
		{
			"id": 1,
			"delivery_date": "2023-09-12",
			"budget_id": 1,
			"order_status": 4,
			"approved_registration_date": "2023-09-12 14:39:32",
			"print_name": "Teste",
			"approved_order_date": "2023-09-12 17:51:15",
			"company_name": "Representada",
			"tranding_name": "Representada",
			"order_created_at": "2023-09-12 17:39:32",
			"order_products": [
				{
					"id": 1,
					"order_id": 1,
					"alt_unitary": null,
					"alt_qtty": null,
					"default_unitary": 2.5,
					"default_qtty": 150
				}
			],
			"budget": {
				"id": 1,
				"client_id": 4,
				"total_cost": 2.5,
				"subtotal": 375,
				"client": {
					"id": 4,
					"type": "client",
					"person_type": "PJ",
					"company_id": null,
					"mood_id": 2,
					"profile_id": null,
					"business_id": 6,
					"image_profile": "",
					"seller_id": 2,
					"agency_id": null,
					"name": null,
					"trading_name": "cliente 01",
					"company_name": "cliente01",
					"birthday": "1970-01-01",
					"email": null,
					"contact": null,
					"website": null,
					"is_whatsapp": null,
					"rg": null,
					"state_registration": "05605605605",
					"cpf_cnpj": "06.505.605/6056-05",
					"cnh": null,
					"voter_registration": null,
					"cep": null,
					"address": null,
					"district": null,
					"number": null,
					"uf": null,
					"city": null,
					"complement": null,
					"token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7ImlzcyI6Im1hZ2F6aW5lYWRtaW4iLCJmaXJzdF9uYW1lIjpudWxsLCJsYXN0X25hbWUiOm51bGwsImVtYWlsIjpudWxsfX0=.m30G2qKR1apIV0vfUSnXoQLcEjiRchYtXVs5Re-obgI",
					"status": true,
					"client_contact": "[{\"id\": 1, \"name\": \"contato01@gmail.com\", \"email\": \"cleberli.rocha@gmail.com\", \"status\": 1, \"client_id\": 4, \"hasBudget\": true, \"cel_number\": \"(18) 91891-8919\", \"department\": \"teste\", \"last_login\": null, \"phone_number\": \"(41) 98198-1891\"}]",
					"client_address": "[{\"uf\": \"PR\", \"cep\": \"83215-270\", \"city\": \"Paranaguá\", \"address\": \"Rua Montevidéu\", \"district\": \"Parque Agari\"}]",
					"last_login": null,
					"created_at": "2023-09-11T13:29:42.000000Z",
					"updated_at": "2023-09-13T18:16:48.000000Z",
					"deleted_at": null
				}
			}
		}
	]
} -->
